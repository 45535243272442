
import { defineComponent, onMounted,ref, reactive } from 'vue';
import Modal from '../components/base/modal.vue'
import CreateForm from '../components/base/createForm.vue'
import { Model, Fields, Form } from '../type/form'
import { contract, getAccount, getAllFundings, Funding, newFunding, getMyFundings, addListener } from '../api/contract'
import { message } from 'ant-design-vue'
import { CheckCircleOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { useRoute  } from 'vue-router'
const $routeQuery = useRoute()  // 用于接收路由参数的
import card from '@/page/Common-components/card-flow.vue'
// pinia
// import { dataSet } from "@/store/index.js";
// const store = dataSet();
import { useRouter } from 'vue-router'
const $router = useRouter()  // 这是路由跳转的

const columns = [
  {
    dataIndex: 'title',
    key: 'title',
    title: 'title'
  },
  {
    title: 'goal',
    dataIndex: 'goal',
    key: 'goal'
  },
  {
    title: 'amount',
    dataIndex: 'amount',
    key: 'amount'
  },
  {
    title: 'myAmount',
    dataIndex: 'myAmount',
    key: 'amount'
  },
  {
    title: 'endTime',
    dataIndex: 'endTime',
    key: 'endTime',
    slots: { customRender: 'time' }
  },
  {
    title: 'success',
    dataIndex: 'success',
    key: 'success',
    slots: { customRender: 'tag' }
  },
  {
    title: 'action',
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' }
  },
]

export default defineComponent({
  name: 'Myself',
  components: { Modal, CreateForm, CheckCircleOutlined, SyncOutlined, CloseCircleOutlined },
  setup() {

 //var url=window.location.search;




    const isOpen = ref<boolean>(false);
    const state = reactive<{loading: boolean, init: Funding[], contr: Funding[]}>({
      loading: true,
      init: [],
      contr: []
    })

    async function fetchData() {
      state.loading = true;
      try {
        const res = await getMyFundings();
        console.log(res,"111111111111")
        state.init = res.init
        state.contr = res.contr
        state.loading = false;
      } catch (e) {
        console.log(e);
        message.error('fail!');
      }
    }






    const router = useRouter();
    const clickFunding = (index : number) => {
      router.push(`/funding/${index}`)
    }
    addListener(fetchData)
    fetchData();

    return { state, columns, clickFunding }
  }
});



